import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import StoryBlock from "../components/titleBlockPages"
import FullWidthCTA from "../components/fullWidthCTABlock"
import DesignDeliver from "../components/design-deliver"
import Testimonials from "../components/testimonials"
import ContactForm from "../components/footerContactBlock"

const PromoItems = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPagepromoitemsJson.edges[0].node;

  const testimonialData = data.allTestimonialspromoitemsJson.edges;
  const nodes = testimonialData.map((item) => { 
    return item.node;
  })


  return (
    <Layout>
      <Seo
        title="Custom Promotional Items and Accessories - Get Started Now"
        description="Print or embroider a wide range of promotional items from water bottles to notebooks.. Get in touch now >>"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <FullWidthCTA
        lsH3={'Interested in Promotional items?'}
      />
      <DesignDeliver sectionName="promotional items" />
      <Testimonials
        bg={"primary"}
        sectionTitleBlock={'What people think'}
        sectionCopy={'More than just pens & polo shirts, we’re experts in brand expression & lasting impressions.'}
        nodes={nodes}
      />
      <StoryBlock
        sectionTitle = {'Sustainability'}
        pageTitle={pageDataEdges.storyBlockTitle}
        pageSubTitle={pageDataEdges.storyBlockSubTitle}
        pageTitleBody={pageDataEdges.storyBlockBody}
        img={pageDataEdges.storyBlockImg}
      />
      
      <ContactForm />
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    
    allPagepromoitemsJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }

          storyBlockBody
          storyBlockTitle
          storyBlockSubTitle
          storyBlockImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }

    allTestimonialspromoitemsJson {
        edges {
        node {
            name
            copy
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
              
            }
        }
        }
    }
}`
export default PromoItems